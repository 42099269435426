import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import JoanLeonImage from "../../content/assets/joan-leon.jpg"
import JosePerezImage from "../../content/assets/jose-perez.jpg"

class Servicios extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Tentang Kami"
        description="Saya bersemangat tentang segala sesuatu yang berkaitan dengan pengembangan dan interaksi antarmuka pengguna di lingkungan web."/>
        <h1>Tentang Kami</h1>
        <div
          style={{
            display: `flex`,
            marginBottom: rhythm(1),
          }}
        >
          <img
            src={JoanLeonImage}
            alt={'Joan Leon'}
            style={{
              height: 100,
              marginRight: rhythm(1),
              marginBottom: 0,
              width: 100,
              borderRadius: `50%`,
            }}
          />
          <h2 style={{marginTop: '2rem'}}>Joan</h2>
        </div>
        <p>Saya bersemangat tentang segala sesuatu yang berkaitan dengan pengembangan dan interaksi antarmuka pengguna di lingkungan web.</p>
        <p>Saya mencoba untuk tetap up to date pada segala sesuatu yang berhubungan dengan HTML5, CSS, SVG, web Animation dan Javascript. Kesukaan saya yang lain adalah berkolaborasi dalam proyek Sumber Terbuka.</p>
        <p>Saya suka berbagi ilmu, baik di MeetUp, Workshop maupun sebagai pengajar di Escuela IT... selalu aktif untuk berbagi semua resource dan berita dari dunia frontend.</p>
        {/* <div
          style={{
            display: `flex`,
            marginBottom: rhythm(1),
            marginTop: rhythm(2),
          }}
        > */}
          {/* <img
            src={JosePerezImage}
            alt={'Jose M. Perez'}
            style={{
              height: 100,
              marginRight: rhythm(1),
              marginBottom: 0,
              width: 100,
              borderRadius: `50%`,
            }}
          />
          <h2 style={{marginTop: '2rem'}}>José M. Pérez</h2>
        </div>
        <p>Soy tech lead, desarrollador full-stack web, especialista web performance, speaker y contribuidor open-source.</p>
        <p>Google Developer Expert en tecnologías web. Me gusta crear productos útiles y con buena experience de usuario.</p>
        <p>Publico en <a href="https://jmperezperez.com">jmperezperez.com</a> sobre mis proyectos y exploraciones de web performance.</p> */}
      </Layout>
    )
  }
}

export default Servicios

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
